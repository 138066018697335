import React, { useEffect, useState } from "react";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure, Spinner, Input} from "@nextui-org/react";
import axios from "axios";
import { athletes_uri, news_uri } from "../../../../utils/url";
import { toast } from "react-toastify";
import { CATEGORY, GENDER, PLACE, TYPE, YEARS } from "../../../../utils/types";
import {Select} from "antd";
import { MdOutlineCancel } from "react-icons/md";


export default function LeaderTeamModal({callback}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [load, setLoad] = useState(false);
  const [data, setData] = useState({selectedKgs:{}, gender:"", athletes:[]});
  const [form, setForm] = useState({year:"", athlete:"", type:""});

  const handlecall = () => {
    callback();
  }

  const Get = async () => {
    try{
      const res = await axios.get(athletes_uri+'/all');
      setData({...data, athletes:res.data});
    }catch(err){
      console.log(err);
    }
  }

  const GetCategory = (e) => {
    const a = CATEGORY.filter((i) => i.value === e);
    setData({...data, category:e, selectedKgs: a[0].data[0]});
    setForm({...form, category:e});
  }

  useEffect(() => {
    Get();

  }, []);

  const onChange = (value) => {
    setForm({...form, athlete: value});
  };

  const onSearch = (value) => {
      console.log('search:');
  };

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  

  const handleSubmit = async () => {
    setLoad(true);
    // const forms = new FormData();
    // forms.append('year', form.year);
    // forms.append('place', form.place);
    // forms.append('athlete', form.athlete);
    // forms.append('category', form.category);
    // forms.append('gender', form.gender);
    // forms.append('kg', form.kg);


    try{
        const res = await axios.post(athletes_uri+'/leader-team', form);
        if(res.status === 200){
            toast.success("Тамирчин нэмэгдлээ !");
            handlecall();
            setIsModalOpen(false);
        }
        setLoad(false);
    }catch(err){
        console.log(err);
        setLoad(false);
    }
  }

  return (
    <>
      <Button className="bg-green-600 text-white ml-2" onPress={()=>setIsModalOpen(true)}>Нэмэх</Button>

      {isModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center z-40">
          <div className="absolute w-full h-full bg-gray-800 opacity-50"></div>
          <div className="bg-white rounded-2xl z-10 xs:w-full xs:h-full md:w-1/2 md:h-3/4 mt-20 p-4">
            <div className="flex justify-between items-center">
              <h1 className="font-bold text-xl mb-6">Шигшээ багийн бүрэлдэхүүн нэмэх</h1>
              <MdOutlineCancel className="cursor-pointer " onClick={()=> setIsModalOpen(false)}/>
            </div>
                <div className="text-sm">
                  <h1 className="mt-2">Он сонгох *</h1>
                    <select className="bg-gray-100 outline-none w-full p-2 rounded-xl ring-1 mt-1" onChange={(e)=> setForm({ ...form, year:e.target.value})}>
                      <option>Сонгох</option>
                      {
                        YEARS.map((it, index) => {
                          return(
                            <>
                              <option value={it.value} key={index}>{it.name}</option>
                            </>
                          )
                        })
                      }
                    </select>

                    <h1 className="mt-2">Төрөл сонгох *</h1>
                    <select className="bg-gray-100 outline-none w-full p-2 rounded-xl ring-1 mt-1" onChange={(e)=> setForm({ ...form, type:e.target.value})}>
                      <option>Сонгох</option>
                      {
                        TYPE.map((it, index) => {
                          return(
                            <>
                              <option value={it.value} key={index}>{it.name}</option>
                            </>
                          )
                        })
                      }
                    </select>

                    <h1 className="mt-2">Тамирчин сонгох *</h1>
                    <Select
                        className='w-full mt-1 bg-gray-100'
                        showSearch
                        placeholder="Тамирчин сонгох"
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch={onSearch}
                        filterOption={filterOption}
                        options={data.athletes.map((it) => ({
                            value: it.id,
                            label: it.username + " " + `(${it.lastname})`,
                        }))}
                    />

                    <div className="gap-2 flex justify-end items-end mt-10">
                      <Button>Болих</Button>
                      {
                        load ?
                        <Button className="ml-2"><Spinner/></Button>
                        :
                        <Button onClick={handleSubmit} className="ml-2">Хадгалах</Button>
                      }
                    </div>

                </div>
          </div>
        </div>
      )}
    </>
  );
}
