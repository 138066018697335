import React, { useState } from "react";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure, Spinner} from "@nextui-org/react";
import axios from "axios";
import { news_uri } from "../../../../utils/url";
import { toast } from "react-toastify";

export default function NewsAddModal({content, data, callback}) {
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  const [load, setLoad] = useState(false);

  const handlecall = () => {
    callback();
  }

  const handleSubmit = async () => {
    setLoad(true);
    const form = new FormData();
    form.append('fb_image', data.fb_image);
    form.append('title', data.title);
    form.append('category', data.category);
    form.append('content', content);

    try{
        const res = await axios.post(news_uri, form);
        if(res.status === 200){
            toast.success("Мэдээ нийтлэгдлээ !");
            onOpenChange(false);
            handlecall();
        }
        setLoad(false);
    }catch(err){
        console.log(err);
        setLoad(false);
    }
}

  return (
    <>
      <Button className="bg-green-600 text-white ml-2" onPress={onOpen}>Хадгалах</Button>
      <Modal size="4xl" isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Нэмэлт мэдээллүүд бөглөх</ModalHeader>
              <ModalBody>
                <div>
                    <h1>Мэдээний гарчиг </h1>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Болих
                </Button>
                {
                  load ?
                  <Button className='bg-green-600 text-white ml-2'><Spinner/></Button>
                  :
                  <Button className='bg-green-600 text-white ml-2' onPress={handleSubmit}>Нэмэх</Button>
                }
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
