import React, { useState } from "react";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure, Spinner, Input, Textarea} from "@nextui-org/react";
import axios from "axios";
import { toast } from "react-toastify";
import { other_uri, shop_uri } from "../../../../utils/url";
import {Select } from "antd";
import { COLORS, PRODUCT_CATEGORY } from "../../../../utils/types";
import { MdOutlineCancel } from "react-icons/md";

export default function CreatePodcast({callback}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [load, setLoad] = useState(false);
  const [data, setData] = useState({
      name:"",link:"",image:""
    });

  const handlecall = () => {
    callback();
  }

  const handleSubmit = async () => {
      setLoad(true);
      const form = new FormData();
      form.append('image', data.image);
      form.append('name', data.name);
      form.append('link', data.link);
    try{
        const res = await axios.post(other_uri+"/podcast", form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        if(res.status === 200){
            toast.success("Подкаст нэмлээ !");
            handlecall();
            setData(
              {...data, 
                name:"",image:"",link:""
              }
            );
            setIsModalOpen(false);
        }
        setLoad(false);
    }catch(err){
        console.log(err);
        setLoad(false);
    }
}



const handleUpload = (e) => {
  if (e?.size <= 5 * 1024 * 1024) {
      if (e?.type === "image/jpeg" || e?.type === "image/jpg" || e?.type === "image/png") {
          setData({...data, image:e});
      } else {
          toast.error("Зөвхөн jpeg/png өргөтгөлтэй зураг оруулах боломжтой !");
      }
  } else {
      toast.error("Файлын хэмжээ 5MB-с бага байх ёстой!");
  }
};

  return (
    <>
      <Button className="bg-green-600 text-white ml-2" onPress={()=>setIsModalOpen(true)}>Подкаст нэмэх</Button>
      {isModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center z-40">
          <div className="absolute w-full h-full bg-gray-800 opacity-50"></div>
          <div className="bg-white rounded-2xl z-10 xs:w-full xs:h-full md:w-1/2 md:h-2/4 mt-20 p-4">
            <div>
              <div className="flex justify-between items-center">
                <h1 className="font-bold text-xl">Подкаст нэмэх</h1>
                <MdOutlineCancel className="cursor-pointer " onClick={()=> setIsModalOpen(false)}/>
              </div>
              <div className="w-full">
                  <h1>Подкастын гарчиг</h1>
                  <Input size="xs" className="mt-2 ring-1 rounded-xl" placeholder="Нэр оруулах" onChange={(e)=> setData({...data, name:e.target.value})}/>
              </div>
              <div className="w-full mt-2">
                  <h1>Линк</h1>
                  <Input size="xs" className="mt-2 ring-1 rounded-xl" placeholder="Линк оруулах" onChange={(e)=> setData({...data, link:e.target.value})}/>
              </div>
              <div className="w-full mt-2">
                  <h1>Зураг</h1>
                  <input onChange={(e) => handleUpload(e.target.files[0])} type="file" size='xs' className='ring-1 rounded-xl mt-2 w-80'/>
              </div>
              <div className="flex justify-end mt-20">
                {
                  load ?
                  <Button className="w-full"><Spinner/></Button>
                  :
                  <Button onPress={handleSubmit} className="w-full">Нэмэх</Button>
                }
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
