import React, { useState } from "react";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure, Spinner, Input, Textarea} from "@nextui-org/react";
import axios from "axios";
import { amjilt_uri, athletes_uri, news_uri, orgs_uri } from "../../../../utils/url";
import { toast } from "react-toastify";

export default function Amjiltuud({callback, datas}) {
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  const [load, setLoad] = useState(false);
  const [data, setData] = useState({comp_name:"", kg:"", medali:"", year:"", athlete_id: datas?.id});

  const handlecall = () => {
    callback();
  }

  const handleSubmit = async () => {
    setLoad(true);

    try{
        const res = await axios.post(amjilt_uri, data);
        if(res.status === 200){
            toast.success("Амжилт нэмлээ !");
            onOpenChange(false);
            handlecall();
        }
        setLoad(false);
    }catch(err){
        console.log(err);
        setLoad(false);
    }
  }

  return (
    <>
      <Button className="bg-gray-200 text-gray-600 ml-2" size="sm" onPress={onOpen}>Амжилт нэмэх</Button>
      <Modal size="4xl" isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Амжилт мэдээлэл</ModalHeader>
              <ModalBody>
                <div className="text-sm">

                    <div className="flex items-center gap-2 w-full">
                      <div className="w-full">
                        <h1>Тэмцээний нэр *</h1>
                        <Input value={data.comp_name} onChange={(e) => setData({...data, comp_name:e.target.value})} size="xs" className="ring-1 rounded-xl mt-1" placeholder="Нэр"/>
                      </div>
                        <div className="w-full">
                          <h1 className="">Кг *</h1>
                          <Input value={data.kg} onChange={(e) => setData({...data, kg:e.target.value})} size="xs" className="ring-1 rounded-xl mt-1" placeholder="Кг"/>
                        </div>
                    </div>

                    <div className="flex items-center gap-2 w-full mt-2">
                      <div className="w-full">
                        <h1>Медаль *</h1>
                        <select className="w-full p-2 mt-1 outline-none ring-1 rounded-md" onChange={(e)=> setData({...data, medali: e.target.value})}>
                          <option>Сонгох</option>
                          <option value="GOLD">Алт</option>
                          <option value="SILVER">Мөнгө</option>
                          <option value="BRONZE">Хүрэл</option>
                        </select>
                      </div>
                        <div className="w-full">
                          <h1 className="">Авсан он *</h1>
                          <Input value={data.year} onChange={(e) => setData({...data, year:e.target.value})} size="xs" className="ring-1 rounded-xl mt-1" placeholder="Он"/>
                        </div>
                    </div>

                  </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Болих
                </Button>
                {
                  load ?
                  <Button className='bg-green-600 text-white ml-2'><Spinner/></Button>
                  :
                  <Button className='bg-green-600 text-white ml-2' onPress={handleSubmit}>Нэмэх</Button>
                }
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
