import React, { useState, useRef, useMemo } from 'react';
import JoditEditor from 'jodit-react';
import { Button, Input, Select } from '@nextui-org/react';
import NewsAddModal from './Modal/news-add-modal';
import { NEWS_TYPES } from '../../../utils/types';
import { toast } from 'react-toastify';

const NewsAdd = ({ placeholder }) => {
	const editor = useRef(null);
	const [content, setContent] = useState('');
  const [data, setData] = useState({title:"", fb_image:"", category:""});

	const config = useMemo(
		() => ({
			readonly: false,
			placeholder: placeholder || 'Start typing...'
		}),
		[placeholder]
	);

  const handleUpload = (e) => {
    if (e?.size <= 5 * 1024 * 1024) {
        if (e?.type === "image/jpeg" || e?.type === "image/jpg" || e?.type === "image/png") {
            setData({...data, fb_image:e});
        } else {
            toast.error("Зөвхөн jpeg/png өргөтгөлтэй зураг оруулах боломжтой !");
        }
    } else {
        toast.error("Файлын хэмжээ 5MB-с бага байх ёстой!");
    }
  };

  function callback(){
    setData({...data, title:"", fb_image:"", category:""});
    setContent('');
  }

	return (
		<div className=''>
      <div className='bg-white p-10 rounded-md'>
        <h1 className='mb-4 uppercase font-semibold'>Мэдээ нэмэх</h1>
        <div className='mt-6 mb-4'>

          <h1>Мэдээний гарчиг*</h1>
          <Input value={data.title} onChange={(e)=> setData({...data, title:e.target.value})} placeholder="Гарчиг оруулах" size='xs' className='ring-1 rounded-xl mt-2'/>

          <h1 className='mt-4'>Share нүүр зураг оруулах*</h1>
          <input onChange={(e) => handleUpload(e.target.files[0])} type="file" size='xs' className='ring-1 rounded-xl mt-2 w-80'/>

          <h1 className='mt-4'>Мэдээний төрөл сонгох*</h1>
          <select className='ring-1 rounded-xl p-2 w-80 bg-gray-100 outline-none mt-2' value={data.category} onChange={(e)=> setData({...data, category:e.target.value})}>
            <option>Сонгох</option>
            <option value={NEWS_TYPES.SIMPLE}>Энгийн мэдээ</option>
            <option value={NEWS_TYPES.HOME_SLIDE}>Нүүр слайд хэсэгт байрлах мэдээ</option>
            <option value={NEWS_TYPES.HIGHLIGHT}>Онцлох мэдээ</option>
          </select>

        </div>
        <JoditEditor
          ref={editor}
          value={content}
          config={config}
          tabIndex={1}
          onBlur={newContent => setContent(newContent)}
          onChange={newContent => {}}
        />
        <div className='flex justify-end mt-8 gap-2'>
          <div className='gap-2'>
            <Button className='bg-gray-200 text-black'>Болих</Button>
            <NewsAddModal content={content} data={data} callback={callback}/>
          </div>
        </div>
      </div>
    </div>
	);
};

export default NewsAdd;
