import React, { useState } from "react";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure, Spinner, Input, Textarea} from "@nextui-org/react";
import axios from "axios";
import { toast } from "react-toastify";
import { other_uri, shop_uri } from "../../../../utils/url";
import {Select } from "antd";
import { COLORS, PRODUCT_CATEGORY } from "../../../../utils/types";
import { MdOutlineCancel } from "react-icons/md";

export default function AddImage({callback, it}) {
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  const [load, setLoad] = useState(false);
  const [data, setData] = useState({
      name:"",image:""
    });

  const handlecall = () => {
    callback();
  }

  const handleSubmit = async () => {
      setLoad(true);
      const form = new FormData();
      form.append('image', data.image);
      form.append('id', it.id);
    try{
        const res = await axios.post(other_uri+"/gallery/add", form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        if(res.status === 200){
            toast.success("Зураг нэмлээ !");
            handlecall();
            setData(
              {...data, 
                name:"",image:""
              }
            );
            // onOpenChange(false);
        }
        setLoad(false);
    }catch(err){
        console.log(err);
        setLoad(false);
    }
}



const handleUpload = (e) => {
  if (e?.size <= 5 * 1024 * 1024) {
      if (e?.type === "image/jpeg" || e?.type === "image/jpg" || e?.type === "image/png") {
          setData({...data, image:e});
      } else {
          toast.error("Зөвхөн jpeg/png өргөтгөлтэй зураг оруулах боломжтой !");
      }
  } else {
      toast.error("Файлын хэмжээ 5MB-с бага байх ёстой!");
  }
};

  return (
    <>
      <Button size="sm" className="bg-gray-200 text-gray-500 ml-2" onPress={onOpen}>Зураг нэмэх</Button>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Modal Title</ModalHeader>
              <ModalBody>
                <div className="w-full mt-2">
                    <h1>Зураг</h1>
                    <input onChange={(e) => handleUpload(e.target.files[0])} type="file" size='xs' className='ring-1 rounded-xl mt-2 w-80'/>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
               {
                load ?
                <Button color="primary">
                  Loading ... 
                </Button>
                :
                <Button color="primary" onPress={handleSubmit}>
                  Нэмэх
                </Button>
               }
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
