import React, { useEffect, useState } from 'react';
import {  Table,  TableHeader,  TableBody,  TableColumn,  TableRow,  TableCell} from "@nextui-org/table";
import AthleteAdd from './Modals/athlete-add';
import { athletes_uri } from '../../../utils/url';
import axios from 'axios';
import { Input } from '@nextui-org/react';
import NationalChampModal from './Modals/national-champ-modal';
import Paginations from '../../../components/Pagination';
import { CATEGORY, GENDER, PLACE } from '../../../utils/types';
import { Button } from 'antd';

const Athletes = () => {
    const [data, setData] = useState([]);
    const [username, setUsername] = useState("");
    const [pagination, setPagination] = useState({limit:20, all:'', totalPage:'', category:'Senior', year:'2024', kg:'65', gender:'', selectedKgs:{}});
    const [page, setPage] = useState(1);
    const [load, setLoad] = useState(false);

    function callback(){
        Get();
    }

    const Get = async () => {
        console.log("A")
        setLoad(true);
        try{
          const res = await axios.get(athletes_uri+`/nationalchamp/admin?username=${username}&page=${page}&limit=${pagination.limit}&category=${pagination.category}&year=${pagination.year}&kg=${pagination.kg}&gender=${pagination.gender}`);
          setPagination({...pagination, all:res.data.all, totalPage:res.data.totalPage});
          setData(res.data.data);
          console.log(res.data)
          setLoad(false);
        }catch(err){
          setLoad(false);
          console.log(err);
        }
      }
    
      useEffect(() => {
        Get();
      }, []);
    
      const handleKeySub = (event) => {
        if (event.key === 'Enter') {
          Get();
        }
      };
    
      const changePage = (page) => {
        setPage(page);
      };
      
      const GetCategory = (e) => {
        const a = CATEGORY.filter((i) => i.value === e);
        setPagination({...pagination, category:e, selectedKgs: a[0].data[0]});
      }
    

  return (
    <div>
        <div className='bg-white p-4 rounded-lg'>
            <div className='flex justify-between items-center'>
                <h1>Улсын аваргууд</h1>
                <div>
                    <NationalChampModal callback={callback}/>
                </div>
            </div>
        </div>
        <div className='mt-2 bg-white rounded-xl p-4'>
            <Input size='xs' onKeyDown={handleKeySub} value={username} onChange={(e)=> setUsername(e.target.value)} className='mb-2 rounded-full' placeholder='Хайлт хийх' rounded="full"/>
            <div className='mb-4 flex items-center gap-4'>
                <select  className="bg-gray-100 outline-none w-full p-2 rounded-xl ring-1 mt-1" onChange={(e)=> GetCategory(e.target.value)}>
                    <option value=''>Сонгох</option>
                    {
                        CATEGORY.map((it, index) => {
                            return(
                                <option value={it.value} key={index}>{it.value}</option>
                            )
                        })
                    }
                </select>
                <select onChange={(e) => setPagination({...pagination, gender:e.target.value})} className="bg-gray-100 outline-none w-full p-2 rounded-xl ring-1 mt-1">
                    <option value=''>Сонгох</option>
                    <option value={GENDER.MALE}>Эрэгтэй</option>
                    <option value={GENDER.FEMALE}>Эмэгтэй</option>
                </select>
                {
                    pagination.selectedKgs &&
                    <div>
                        {
                            pagination.gender === GENDER.MALE &&
                            <select onChange={(e) => setPagination({...pagination, kg:e.target.value})} className="bg-gray-100 outline-none w-28 p-2 rounded-xl ring-1 mt-1">
                                <option value=''>Сонгох</option>
                            {
                                pagination.selectedKgs.male.map((it, index) => {
                                return(
                                    <option value={it.kg} key={index}>{it.kg}</option>
                                )
                                })
                            }
                            </select>
                        }
                        {
                            pagination.gender === GENDER.FEMALE &&
                            <select onChange={(e) => setPagination({...pagination, kg:e.target.value})} className="bg-gray-100 outline-none w-28 p-2 rounded-xl ring-1 mt-1">
                                <option value=''>Сонгох</option>
                            {
                                pagination.selectedKgs.female.map((it, index) => {
                                return(
                                    <option key={index} value={it.kg}>{it.kg}</option>
                                )
                                })
                            }
                            </select>
                        }
                    </div>
                }
                <Button onClick={callback}>Хайх</Button>
            </div>
            <Table aria-label="Example static collection table">
                <TableHeader>
                    <TableColumn>Эзэлсэн байр</TableColumn>
                    <TableColumn>Тамирчин</TableColumn>
                    <TableColumn>Төрсөн он</TableColumn>
                    <TableColumn>Харъяалал</TableColumn>
                </TableHeader>
                <TableBody>
                    {
                        data.map((it, index) => {
                            return(
                                <TableRow key={index}>
                                    <TableCell><span className='bg-gray-200 rounded-full p-1'>{it.place}</span> <span className='text-red-800 font-semibold'>{it.year} он</span> <span className='font-bold'>{it.kg} кг</span></TableCell>
                                    <TableCell>
                                        <div className='flex items-center gap-2'>
                                            <img src={it.athlete.profile_img} className='h-10 w-10'/>
                                            <div className=''>
                                                <h1 className='text-xs'>{it.athlete.lastname}</h1>
                                                <h1 className='text-sm font-semibold'>{it.athlete.username}</h1>
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell>{it.athlete.birth_date}</TableCell>
                                    <TableCell>{it.athlete.club}</TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
            <Paginations initialPage={page} total={pagination.totalPage} alldata={pagination.all} onChange={changePage}/> 
        </div>
    </div>
  )
}

export default Athletes