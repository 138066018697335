import React from 'react'

const Home = () => {
  return (
    <div className='w-full'>
      <div className='grid xs:grid-cols-1 md:grid-cols-3 gap-2'>
        <div className='grid col-span-1'>
          <div className='bg-white rounded-md p-4'>
            <h1>Ангилал</h1>
          </div>
        </div>
        <div className='grid col-span-1'>
          <div className='bg-white rounded-md p-4'>
            <h1>Ангилал</h1>
          </div>
        </div>
        <div className='grid col-span-1'>
          <div className='bg-white rounded-md p-4'>
            <h1>Ангилал</h1>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home