// const mainUrl = "http://localhost:5000";
const mainUrl = "https://wrestlingbackend.zaancargo.com"

//auth urls
export const news_uri = mainUrl + "/news";
export const athletes_uri = mainUrl + "/athletes";
export const orgs_uri = mainUrl + "/orgs";
export const events_uri = mainUrl + "/events";
export const shop_uri = mainUrl + "/shop";
export const other_uri = mainUrl + "/other";
export const donation_uri = mainUrl + "/donation";
export const amjilt_uri = mainUrl + "/amjilt";
export const admin_uri = mainUrl + "/admin";