import React, { useEffect, useState } from 'react';
import {  Table,  TableHeader,  TableBody,  TableColumn,  TableRow,  TableCell} from "@nextui-org/table";
import { athletes_uri, orgs_uri } from '../../../utils/url';
import axios from 'axios';
import { Input } from '@nextui-org/react';
import OrgAdd from './Modals/org-add.js';
import Paginations from '../../../components/Pagination/index.js';

const Orgs = () => {
    const [data, setData] = useState([]);
    const [username, setUsername] = useState("");
    const [pagination, setPagination] = useState({limit:20, all:'', totalPage:''});
    const [page, setPage] = useState(1);
    const [load, setLoad] = useState(false);

    function callback(){
        Get();
    }

    const Get = async () => {
        setLoad(true);
        try{
          const res = await axios.get(orgs_uri+`?username=${username}&page=${page}&limit=${pagination.limit}`);
          setPagination({...pagination, all:res.data.all, totalPage:res.data.totalPage});
          setData(res.data.data);
          setLoad(false);
        }catch(err){
          setLoad(false);
          console.log(err);
        }
      }
    
      useEffect(() => {
        Get();
      }, []);
    
      const handleKeySub = (event) => {
        if (event.key === 'Enter') {
          Get();
        }
      };
    
      const changePage = (page) => {
        setPage(page);
      };
      
    

  return (
    <div>
        <div className='bg-white p-4 rounded-lg'>
            <div className='flex justify-between items-center'>
                <h1>Тамирчид</h1>
                <div>
                    <OrgAdd callback={callback}/>
                </div>
            </div>
        </div>
        <div className='mt-2 bg-white rounded-xl p-4'>
            <Input size='xs' onKeyDown={handleKeySub} value={username} onChange={(e)=> setUsername(e.target.value)} className='mb-4 rounded-full' placeholder='Хайлт хийх' rounded="full"/>
            <Table aria-label="Example static collection table">
                <TableHeader>
                    <TableColumn>Тамирчин</TableColumn>
                    <TableColumn>Үүсгэсэн он</TableColumn>
                    <TableColumn></TableColumn>
                </TableHeader>
                <TableBody>
                    {
                        data.map((it, index) => {
                            return(
                                <TableRow key={index}>
                                    <TableCell>
                                        <div className='flex items-center gap-2'>
                                            <img src={it.logo} className='h-10 w-10'/>
                                            <div className=''>
                                                <h1 className='text-xs'>{it.name}</h1>
                                                <h1 className='text-sm font-semibold'>{it.short_name}</h1>
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell>{it.created_at}</TableCell>
                                    <TableCell>
                                        
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
            <Paginations initialPage={page} total={pagination.totalPage} alldata={pagination.all} onChange={changePage}/> 
        </div>
    </div>
  )
}

export default Orgs