import { createContext, useEffect, useState } from "react";
import axios from "axios";
import {toast} from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { admin_uri } from "../utils/url";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
    const navigate = useNavigate();
    const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem("WRESTLING-ADMIN")) || null)

    const login = async(user) => {
        try{
            const res = await axios.post(admin_uri+`/login`, user)
            if(res.status === 204){
                return toast.error("Ийм админ байхгүй байна")
            }

            if(res.status === 205){
                return toast.error("Нууц үг буруу байна !")
            }

            setCurrentUser(res.data);
            return navigate('/dashboard');
        }catch(err){
            console.log(err);
        }
    }

    const logout = async(user) => {
        // await axios.post(logoutUri, user)
        setCurrentUser(null)
    };

    useEffect(()=>{
        localStorage.setItem("WRESTLING-ADMIN", JSON.stringify(currentUser));
    }, [currentUser]);

    return(
        <AuthContext.Provider value={{currentUser, login, logout}}>
            {children}
        </AuthContext.Provider>
    )

}
