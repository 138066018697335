

export const TYPES = {
    ALL: "ALL",
    REQUESTED: "REQUESTED",
    EREEN: "EREEN",
    IRSEN: "IRSEN",
    CONFIRM: "CONFIRM",
    REJECTED: "REJECTED",
    APPROVED: "APPROVED",
}

export const GENDER = {
    MALE: "MALE",
    FEMALE: "FEMALE",
}

export const ODON_MEDALI = [
    {value: "H_BAATAR",name: "Хөдөлмөрийн баатар"},
    {value: "H_GAWYA_ODON",name: "Хөдөлмөрийн гавьяаны улаан тугийн одон"},
    {value: "ALTANGADAS",name: "Алтангадас"},
    {value: "G_TAMIRCHIN",name: "Гавьяат тамирчин"},
    {value: "S_MASTER",name: "Спортын мастер"},
    {value: "S_DED_MASTER",name: "Спортын дэд мастер"},
    {value: "OUHM",name: "Олон улсын хэмжээний мастер"},
];

export const PLACE = [
    {value: "1",name: "1-р байр"},
    { value: "2",name: "2-р байр"},
    { value: "3",name: "3-р байр"},
    {value: "4",name: "4-р байр"},
    {value: "5",name: "5-р байр"},
    {value: "6",name: "6-р байр"},
]

export const TYPE = [
    {value: "COACH",name: "Дасгалжуулагч"},
    { value: "ATHLETE",name: "Тамирчин"},
]

export const TYPEJSON = 
{
    COACH: "COACH",
    ATHLETE: "ATHLETE",
}

export const WRESTLING_TYPE = [
    {name: "Улсын аварга",value: "NATIONAL_CHAMP"},
    {name: "Нэрэмжит тэмцээн", value: "MENTION_CHAMP"},
    {name: "Олон улсын аварга",value: "MANY_CITY_CHAMP"}
]

export const WRESTLING_AGE = [
    {name: "U15",value: "U15"},
    {name: "U17",value: "U17"},
    {name: "U19",value: "U19"},
    {name: "U21",value: "U21"},
    {name: "U23",value: "U23"},
    {name: "Senior",value: "Senior"},
]

export const YEARS = [
    {value: "2024",name: "2024"},
    {value: "2025",name: "2025"},
    {value: "2026",name: "2026"},
    {value: "2027",name: "2027"},
    {value: "2028",name: "2028"},
];

export const WRESTLING_STYLE = [
    {value: "FREE_STYLE",name: "Freestyle"},
    {value: "GRECE_ROMAN",name: "Greco-Roman"},
    {value: "WOMANS_WRESTLING",name: "Women's Wrestling"},
    {value: "KAZAK_KURESI",name: "Kazak Kuresi"},
    {value: "BEACH_WRESTLING",name: "Beach Wrestling"},
    {value:"GRAPLING",name:"Grappling"}
];

export const PRODUCT_CATEGORY = [
    {value: "SHOES",name: "Гутал",
        size: [
            {value: "36"},
            {value: "37"},
            {value: "38"},
            {value: "39"},
            {value: "40"},
            {value: "41"},
            {value: "42"},
            {value: "43"},
            {value: "44"},
            {value: "Free size"},
        ]
    },
    {value: "T_SHIRT",name: "Подволк",
        size: [
            {value: "S"},
            {value: "M"},
            {value: "XL"},
            {value: "XXL"},
            {value: "XXXL"},
            {value: "4XL"},
            {value: "5XL"},
            {value: "Free size"},
        ]
    },
    {value: "SHIRT", name: "Цамц",
        size: [
            {value: "S"},
            {value: "M"},
            {value: "XL"},
            {value: "XXL"},
            {value: "XXXL"},
            {value: "4XL"},
            {value: "5XL"},
            {value: "Free size"},
        ]
    },
    {value: "Тирко",name: "TIRKO",
        size: [
            {value: "S"},
            {value: "M"},
            {value: "XL"},
            {value: "XXL"},
            {value: "XXXL"},
            {value: "4XL"},
            {value: "5XL"},
            {value: "Free size"},
        ]
    },
    {value: "SOUVENIRS",name: "Бэлэг дурсгал", size:[{value: "Free size"}]}
];

export const COLORS = [
    { name: "Цагаан", value: "WHITE" },
    { name: "Хар", value: "BLACK" },
    { name: "Бор", value: "BROWN" },
    { name: "Цэнхэр", value: "BLUE" },
    { name: "Улаан", value: "RED" },
    { name: "Ногоон", value: "GREEN" },
    { name: "Шар", value: "YELLOW" },
    { name: "Нил ягаан", value: "PURPLE" },
    { name: "Саарал", value: "GRAY" },
    { name: "Ягаан", value: "PINK" },
    { name: "Улаан хүрэн", value: "MAROON" },
    { name: "Улбар шар", value: "ORANGE" }
];


export const NEWS_TYPES = {
    HOME_SLIDE:"HOME_SLIDE",
    SIMPLE:"ENGIIN",
    HIGHLIGHT:"HIGHLIGHT"
    // N: "Энгийн мэдээ",
    // Y: "Нүүр слайд хэсэгт байрлах мэдээ",
    // s:"Онцлох мэдээ"
}


export const CATEGORY = [
    {
        value: "U9",
        data: [
            {
                male: [
                    { kg: "19" },
                    { kg: "22" },
                    { kg: "25" },
                    { kg: "28" },
                    { kg: "32" },
                    { kg: "36" }
                ],
                female: [
                    { kg: "20" },
                    { kg: "23" },
                    { kg: "25" },
                    { kg: "28" },
                    { kg: "31" },
                    { kg: "35" }
                ]
            }
        ]
    },
    {
        value: "U11",
        data: [
            {
                male: [
                    { kg: "23" },
                    { kg: "26" },
                    { kg: "29" },
                    { kg: "33" },
                    { kg: "36" },
                    { kg: "40" },
                    { kg: "44-50" },
                    { kg: "50-65" }
                ],
                female: [
                    { kg: "24" },
                    { kg: "28" },
                    { kg: "32" },
                    { kg: "36" },
                    { kg: "40" },
                    { kg: "45" }
                ]
            }
        ]
    },
    {
        value: "U13",
        data: [
            {
                male: [
                    { kg: "27" },
                    { kg: "30" },
                    { kg: "34" },
                    { kg: "38" },
                    { kg: "42" },
                    { kg: "46" },
                    { kg: "50" },
                    { kg: "55-60" }
                ],
                female: [
                    { kg: "30" },
                    { kg: "34" },
                    { kg: "38" },
                    { kg: "44" },
                    { kg: "50" },
                    { kg: "60" }
                ]
            }
        ]
    },
    {
        value: "U15",
        data: [
            {
                male: [
                    { kg: "38" },
                    { kg: "41" },
                    { kg: "44" },
                    { kg: "48" },
                    { kg: "52" },
                    { kg: "57" },
                    { kg: "62" },
                    { kg: "68" },
                    { kg: "75" },
                    { kg: "85" }
                ],
                female: [
                    { kg: "33" },
                    { kg: "36" },
                    { kg: "39" },
                    { kg: "42" },
                    { kg: "46" },
                    { kg: "50" },
                    { kg: "54" },
                    { kg: "58" },
                    { kg: "62" },
                    { kg: "66" }
                ]
            }
        ]
    },
    {
        value: "U17",
        data: [
            {
                male: [
                    { kg: "45" },
                    { kg: "48" },
                    { kg: "51" },
                    { kg: "55" },
                    { kg: "60" },
                    { kg: "65" },
                    { kg: "71" },
                    { kg: "80" },
                    { kg: "92" },
                    { kg: "110" }
                ],
                female: [
                    { kg: "40" },
                    { kg: "43" },
                    { kg: "46" },
                    { kg: "49" },
                    { kg: "53" },
                    { kg: "57" },
                    { kg: "61" },
                    { kg: "65" },
                    { kg: "69" },
                    { kg: "73" }
                ]
            }
        ]
    },
    {
        value: "U20",
        data: [
            {
                male: [
                    { kg: "57" },
                    { kg: "61" },
                    { kg: "65" },
                    { kg: "70" },
                    { kg: "74" },
                    { kg: "79" },
                    { kg: "86" },
                    { kg: "92" },
                    { kg: "97" },
                    { kg: "125" }
                ],
                female: [
                    { kg: "50" },
                    { kg: "53" },
                    { kg: "55" },
                    { kg: "57" },
                    { kg: "59" },
                    { kg: "62" },
                    { kg: "65" },
                    { kg: "68" },
                    { kg: "72" },
                    { kg: "76" }
                ]
            }
        ]
    },
    {
        value: "U23",
        data: [
            {
                male: [
                    { kg: "57" },
                    { kg: "61" },
                    { kg: "65" },
                    { kg: "70" },
                    { kg: "74" },
                    { kg: "79" },
                    { kg: "86" },
                    { kg: "92" },
                    { kg: "97" },
                    { kg: "125" }
                ],
                female: [
                    { kg: "50" },
                    { kg: "53" },
                    { kg: "55" },
                    { kg: "57" },
                    { kg: "59" },
                    { kg: "62" },
                    { kg: "65" },
                    { kg: "68" },
                    { kg: "72" },
                    { kg: "76" }
                ]
            }
        ]
    },
    {
        value: "Senior",
        data: [
            {
                male: [
                    { kg: "57" },
                    { kg: "61" },
                    { kg: "65" },
                    { kg: "70" },
                    { kg: "74" },
                    { kg: "79" },
                    { kg: "86" },
                    { kg: "92" },
                    { kg: "97" },
                    { kg: "125" }
                ],
                female: [
                    { kg: "50" },
                    { kg: "53" },
                    { kg: "55" },
                    { kg: "57" },
                    { kg: "59" },
                    { kg: "62" },
                    { kg: "65" },
                    { kg: "68" },
                    { kg: "72" },
                    { kg: "76" }
                ]
            }
        ]
    },
    {
        value: "Classic",
        data: [
            {
                male: [
                    { kg: "60" },
                    { kg: "67" },
                    { kg: "77" },
                    { kg: "87" },
                    { kg: "97" },
                    { kg: "130" }
                ],
                female: []
            }
        ]
    }
]
