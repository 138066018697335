import React, { useState } from "react";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure, Spinner, Input, Textarea} from "@nextui-org/react";
import axios from "axios";
import { athletes_uri, news_uri, orgs_uri } from "../../../../utils/url";
import { toast } from "react-toastify";

export default function OrgAdd({callback}) {
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  const [load, setLoad] = useState(false);
  const [data, setData] = useState({name:"", short_name:"", phone_no:"", email:"", logo:"", province:"", sum:""});

  const handlecall = () => {
    callback();
  }

  const handleUpload = (e) => {
    if (e?.size <= 5 * 1024 * 1024) {
        if (e?.type === "image/jpeg" || e?.type === "image/jpg" || e?.type === "image/png") {
            setData({...data, logo:e});
        } else {
            toast.error("Зөвхөн jpeg/png өргөтгөлтэй зураг оруулах боломжтой !");
        }
    } else {
        toast.error("Файлын хэмжээ 5MB-с бага байх ёстой!");
    }
  };

  const handleSubmit = async () => {
    setLoad(true);
    const form = new FormData();
    form.append('name', data.name);
    form.append('short_name', data.short_name);
    form.append('phone_no', data.phone_no);
    form.append('email', data.email);
    form.append('logo', data.logo);
    form.append('province', data.province);
    form.append('sum', data.sum);

    try{
        const res = await axios.post(orgs_uri, form);
        if(res.status === 200){
            toast.success("Байгууллага нэмлээ !");
            onOpenChange(false);
            handlecall();
        }
        if(res.status === 204){
          toast.success("Таны нэр эсвэл имейл хаяг аль хэдийн бүртгэлтэй байна !");
        }
        setLoad(false);
    }catch(err){
        console.log(err);
        setLoad(false);
    }
  }

  return (
    <>
      <Button className="bg-blue-600 text-white ml-2" onPress={onOpen}>Байгууллага нэмэх</Button>
      <Modal size="4xl" isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Байгууллага мэдээлэл</ModalHeader>
              <ModalBody>
                <div className="text-sm">

                    <div className="flex items-center gap-2 w-full">
                      <div className="w-full">
                        <h1>Нэр *</h1>
                        <Input value={data.name} onChange={(e) => setData({...data, name:e.target.value})} size="xs" className="ring-1 rounded-xl mt-1" placeholder="Алдар спорт хороо"/>
                      </div>
                        <div className="w-full">
                          <h1 className="">Товчилсон *</h1>
                          <Input value={data.short_name} onChange={(e) => setData({...data, short_name:e.target.value})} size="xs" className="ring-1 rounded-xl mt-1" placeholder="АЛД"/>
                        </div>
                    </div>

                    <h1 className='mt-2'>Лого оруулах *</h1>
                    <input onChange={(e) => handleUpload(e.target.files[0])} type="file" size='xs' className='ring-1 rounded-xl mt-1 w-80'/>

                    <div className="flex items-center gap-2 w-full mt-2">
                      <div className="w-full">
                        <h1>Утас *</h1>
                        <Input value={data.phone_no} onChange={(e) => setData({...data, phone_no:e.target.value})} size="xs" className="ring-1 rounded-xl mt-1" placeholder="Утас"/>
                      </div>
                        <div className="w-full">
                          <h1 className="">Имейл *</h1>
                          <Input value={data.email} onChange={(e) => setData({...data, email:e.target.value})} size="xs" className="ring-1 rounded-xl mt-1" placeholder="Имейл"/>
                        </div>
                    </div>

                    <div className="flex items-center gap-2 w-full mt-2">
                      <div className="w-full">
                        <h1>Аймаг/Дүүрэг *</h1>
                        <Input value={data.lastname} onChange={(e) => setData({...data, province:e.target.value})} size="xs" className="ring-1 rounded-xl mt-1" placeholder="Утас"/>
                      </div>
                      <div className="w-full">
                        <h1 className="">Сум/Хороо *</h1>
                        <Input value={data.username} onChange={(e) => setData({...data, sum:e.target.value})} size="xs" className="ring-1 rounded-xl mt-1" placeholder="Имейл"/>
                      </div>
                    </div>
                    
                  </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Болих
                </Button>
                {
                  load ?
                  <Button className='bg-green-600 text-white ml-2'><Spinner/></Button>
                  :
                  <Button className='bg-green-600 text-white ml-2' onPress={handleSubmit}>Нэмэх</Button>
                }
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
