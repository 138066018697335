import React, { useState } from "react";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure, Spinner, Input, Textarea} from "@nextui-org/react";
import axios from "axios";
import { toast } from "react-toastify";
import { shop_uri } from "../../../../utils/url";
import {Select } from "antd";
import { COLORS, PRODUCT_CATEGORY } from "../../../../utils/types";
import { MdOutlineCancel } from "react-icons/md";

export default function CreateProduct({callback}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [load, setLoad] = useState(false);
  const [data, setData] = useState(
    {
        name:"",price:"",desc:"",
        size:[],color:[],quantity:"", category:"",
        image_1:"",image_2:"",image_3:"",image_4:"",
    });
  const [size, setSize] = useState([]);
  const [color, setColor] = useState(COLORS);

  const handlecall = () => {
    callback();
  }

  const handleSubmit = async () => {
      setLoad(true);
      const form = new FormData();
      form.append('image1', data.image_1);
      form.append('image2', data.image_2);
      form.append('image3', data.image_3);
      form.append('image4', data.image_4);
      form.append('name', data.name);
      form.append('price', data.price);
      form.append('desc', data.desc);
      form.append('size', data.size);
      form.append('color', data.color);
      form.append('quantity', data.quantity);
      form.append('category', data.category);
    try{
        const res = await axios.post(shop_uri, form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        if(res.status === 200){
            toast.success("Бараа нэмлээ !");
            handlecall();
            setData(
              {...data, 
                name:"",price:"",desc:"",size:[],color:[],
                quantity:"",category:"",image_1:"",
                image_2:"",image_3:"",image_4:""
              }
            );
            setIsModalOpen(false);
        }
        setLoad(false);
    }catch(err){
        console.log(err);
        setLoad(false);
    }
}

const handleUpload = ({e, type}) => {
  if (e?.size <= 5 * 1024 * 1024) {
      if (e?.type === "image/jpeg" || e?.type === "image/jpg" || e?.type === "image/png") {
        if(type == '1'){
          setData({...data, image_1:e});
        }
        if(type == '2'){
          setData({...data, image_2:e});
        }
        if(type == '3'){
          setData({...data, image_3:e});
        }
        if(type == '4'){
          setData({...data, image_4:e});
        }
      } else {
          toast.error("Зөвхөн jpeg/png өргөтгөлтэй зураг оруулах боломжтой !");
      }
  } else {
      toast.error("Файлын хэмжээ 5MB-с бага байх ёстой!");
  }
};

const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

const onChangeSize = (value) => {
  setData((prevData) => ({
    ...prevData,
    size: value,
  }));
};

const onChangeColor = (value) => {
  setData((prevData) => ({
    ...prevData,
    color: value,
  }));
};

const onChangeCategory = (e) => {
    const a = PRODUCT_CATEGORY.filter((i) => i.value === e);
    setData({...data, category:e});
    setSize(a[0].size);
};

  return (
    <>
      <Button className="bg-green-600 text-white ml-2" onPress={()=>setIsModalOpen(true)}>Бараа нэмэх</Button>
      {isModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center z-40">
          <div className="absolute w-full h-full bg-gray-800 opacity-50"></div>
          <div className="bg-white rounded-2xl z-10 xs:w-full xs:h-full md:w-1/2 md:h-3/4 mt-20 p-4">
            <div>
              <div className="flex justify-between items-center">
                <h1 className="font-bold text-xl">Бараа нэмэх</h1>
                <MdOutlineCancel className="cursor-pointer " onClick={()=> setIsModalOpen(false)}/>
              </div>
              <div className="flex w-full gap-2">
                  <div className="w-full">
                      <h1>Нэр</h1>
                      <Input size="xs" className="mt-2 ring-1 rounded-xl" placeholder="Нэр оруулах" onChange={(e)=> setData({...data, name:e.target.value})}/>
                  </div>
                  <div className="w-full">
                      <h1>Үнэ</h1>
                      <Input type="number" size="xs" className="mt-2 ring-1 rounded-xl" placeholder="Үнэ оруулах" onChange={(e)=> setData({...data, price:e.target.value})}/>
                  </div>
              </div>
              <div className="flex w-full gap-2 mt-3">
                  <div className="w-full">
                      <h1>Тайлбар</h1>
                      <Textarea size="xs" className="mt-2 ring-1 rounded-xl" placeholder="Тайлбар оруулах" onChange={(e)=> setData({...data, desc:e.target.value})}/>
                  </div>
                  <div className="w-full">
                      <h1>Нийт тоо</h1>
                      <Input type="number" size="xs" className="mt-2 ring-1 rounded-xl" placeholder="Нийт тоо оруулах" onChange={(e)=> setData({...data, quantity:e.target.value})}/>
                  </div>
              </div>
              <div className="flex w-full gap-2 mt-3">
                  <div className="w-full">
                      <h1>Барааны төрөл сонгох</h1>
                      <select className="p-2 rounded-md ring-1 w-full mt-1 outline-none" onChange={(e) => onChangeCategory(e.target.value)}>
                        <option>Сонгох</option>
                        {
                          PRODUCT_CATEGORY.map((it, index) => {
                            return(
                              <option value={it.value} key={index}>{it.name}</option>
                            )
                          })
                        }
                      </select>
                  </div>
              </div>


              <div className="flex w-full gap-2 mt-3">
                  <div className="w-full">
                    <h1>Хэмжээ</h1>
                    <Select
                        className='w-full mt-2'
                        showSearch
                        mode="multiple"
                        placeholder="Бараа сонгох"
                        optionFilterProp="children"
                        onChange={onChangeSize}
                        filterOption={filterOption}
                        options={size.map((product) => ({
                            value: product.value,
                            label: product.value,
                        }))}
                    />
                  </div>
                  <div className="w-full">
                    <h1>Өнгө</h1>
                    <Select
                        className='w-full mt-2'
                        showSearch
                        mode="multiple"
                        placeholder="Бараа сонгох"
                        optionFilterProp="children"
                        onChange={onChangeColor}
                        filterOption={filterOption}
                        options={color.map((product) => ({
                            value: product.value,
                            label: product.name,
                        }))}
                    />
                  </div>
              </div>
              <div className="flex w-full gap-2 mt-3">
                  <div className="w-full">
                      <h1>Үндсэн том зураг</h1>
                      <input type="file" className="mt-2" placeholder="" onChange={(e) => handleUpload({e:e.target.files[0], type:'1'})}/>
                  </div>
                  <div className="w-full">
                      <h1>Зураг 1</h1>
                      <input type="file" className="mt-2" placeholder="" onChange={(e) => handleUpload({e:e.target.files[0], type:'2'})}/>
                  </div>
              </div>
              <div className="flex w-full gap-2 mt-3">
                  <div className="w-full">
                      <h1>Зураг 2</h1>
                      <input type="file" className="mt-2" placeholder="" onChange={(e) => handleUpload({e:e.target.files[0], type:'3'})}/>
                  </div>
                  <div className="w-full">
                      <h1>Зураг 3</h1>
                      <input type="file" className="mt-2" placeholder="" onChange={(e) => handleUpload({e:e.target.files[0], type:'4'})}/>
                  </div>
              </div>
              <div className="flex justify-end mt-20">
                {
                  load?
                  <Button className="w-full"><Spinner/></Button>
                  :
                  <Button onPress={handleSubmit} className="w-full">Нэмэх</Button>
                }
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
