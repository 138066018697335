import React, { useEffect, useState } from 'react';
import { CiCalendarDate } from "react-icons/ci";
import CalendarAddModal from './Widgets/calendarAddModal';
import axios from "axios";
import {  Table,  TableHeader,  TableBody,  TableColumn,  TableRow,  TableCell} from "@nextui-org/table";
import { Input, Spinner } from '@nextui-org/react';
import { events_uri } from '../../../utils/url';
import Paginations from '../../../components/Pagination';
import EditModal from './Widgets/editModal';


const Calendar = () => {
    const [name, setName] = useState("");
    const [pagination, setPagination] = useState({limit:20, all:'', totalPage:''});
    const [page, setPage] = useState(1);
    const [load, setLoad] = useState(false);
    const [data, setData] = useState([]);

    function callback(){

    }

    const Get = async () => {
      setLoad(true);
      try{
        const res = await axios.get(events_uri+`?name=${name}&page=${page}&limit=${pagination.limit}`);
        setPagination({...pagination, all:res.data.all, totalPage:res.data.totalPage});
        setData(res.data.data);
        setLoad(false);
      }catch(err){
        setLoad(false);
        console.log(err);
      }
    }
  
    useEffect(() => {
      Get();
    }, []);
  
    const handleKeySub = (event) => {
      if (event.key === 'Enter') {
        Get();
      }
    };
  
    const changePage = (page) => {
      setPage(page);
    };
    


  return (
    <div>
      <div className='bg-white rounded-md p-4'>
        <div className='flex items-center justify-between'>
          <div className='flex items-center gap-2'>
            <CiCalendarDate size={24}/>
            <h1 className=''>Тэмцээний календар</h1>
          </div>
          <CalendarAddModal/>
        </div>
        <Input size='xs' onKeyDown={handleKeySub} value={name} onChange={(e)=> setName(e.target.value)} className='mb-4 rounded-full mt-2' placeholder='Хайлт хийх' rounded="full"/>
            <Table aria-label="Example static collection table">
              <TableHeader>
                  <TableColumn>Тэмцээний нэр</TableColumn>
                  <TableColumn>Хугацаа</TableColumn>
                  <TableColumn>Зохиогдох газар</TableColumn>
                  <TableColumn></TableColumn>
              </TableHeader>
              <TableBody>
                  {
                      data.map((it, index) => {
                          return(
                              <TableRow key={index}>
                                  <TableCell>{it.name}</TableCell>
                                  <TableCell>{it.year} {it.month} {it.day}</TableCell>
                                  <TableCell>{it.province}</TableCell>
                                  <TableCell><EditModal id={it.id} callback={callback}/></TableCell>
                              </TableRow>
                          )
                      })
                  }
              </TableBody>
            </Table>
            <Paginations initialPage={page} total={pagination.totalPage} alldata={pagination.all} onChange={changePage}/> 
            {
              load &&
              <div><Spinner/></div>
            }
      </div>
    </div>
  )
}

export default Calendar