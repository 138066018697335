import React, { useState } from 'react';
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure} from "@nextui-org/react";
import axios from 'axios';
import { donation_uri } from '../../../../utils/url';
import { Spin } from 'antd';
import { toast } from 'react-toastify';

const StatusUpdate = ({callback, data}) => {
    const {isOpen, onOpen, onOpenChange} = useDisclosure();
    const [load, setLoad] = useState(false);

    function handleCall(){
        callback();
    }

    const sub = async () => {
        setLoad(true);
        try{
            const res = await axios.patch(donation_uri+`/${data.id}`);
            toast.success("Амжилттай");
            setLoad(false);
            onOpenChange(false);
            handleCall();
        }catch(err){
            console.log(err);
            setLoad(false);
        }
    }

  return (
     <>
      <Button size='sm' onPress={onOpen}>Дэлгэрэнгүй</Button>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Хандив</ModalHeader>
              <ModalBody>
                Хандивын мөнгө шилжүүлсэн үү ?
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                {
                    load ?
                    <Button color="primary" onPress={onClose}>
                        <Spin/>
                    </Button>
                    :
                    <Button color="primary" onPress={sub}>
                        Тийм
                    </Button>
                }
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}

export default StatusUpdate