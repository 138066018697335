import React, { useState } from "react";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter,
     Button, useDisclosure, Input,
     Select, SelectItem,
     Spinner
    } from "@nextui-org/react";
import { CATEGORY, WRESTLING_STYLE, WRESTLING_TYPE } from "../../../../utils/types";
import axios from "axios";
import { events_uri } from "../../../../utils/url";
import {toast} from "react-toastify";

export default function CalendarAddModal({callback}) {
  const [load, setLoad] = useState(false);
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  const [data, setData] = useState({name:"", province:"", year:"", month:"", day:"", type:"", category:"", event_category:""});

  const call = () => {
    callback();
  }

  const Submit = async()=>{
    setLoad(true);
    try{  
      const res = await axios.post(events_uri, data);
      if(res.status === 200){
        toast.success("Амжилттай !");
        call();
        setData({...data, name:"", province:"", year:"", month:"", day:"", type:"", category:"", event_category:""});
        onOpenChange(false);
      }
      setLoad(false);
    }catch(err){
      console.log(err);
      setLoad(false);
    }
  }

  return (
    <>
      <Button onPress={onOpen}>Нэмэх</Button>
      <Modal size="5xl" isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Тэмцээний календарь нэмэх</ModalHeader>
              <ModalBody>
                <div>
                    <h1>Тэмцээний нэр *</h1>
                    <Input onChange={(e) => setData({...data, name: e.target.value})} value={data.name} placeholder="Тэмцээний нэр" size="sm" className="mt-2 ring-1 rounded-md"/>
                    <h1 className="mt-4">Зохиогдох газар *</h1>
                    <Input onChange={(e) => setData({...data, province: e.target.value})} value={data.province} placeholder="Зохиогдох газар" size="sm" className="mt-2 ring-1 rounded-md"/>
                    <div className="flex gap-2">
                       <div>
                        <div>
                              <h1 className="mt-4">Тэмцээний он *</h1>
                              <Input onChange={(e) => setData({...data, year: e.target.value})} value={data.year} placeholder="2024" size="xs" className="mt-2 ring-1 rounded-md"/>
                          </div>
                          <div className="w-20">
                              <h1 className="mt-1">сар *</h1>
                              <Input onChange={(e) => setData({...data, month: e.target.value})} value={data.month} placeholder="1" size="xs" className="mt-2 ring-1 rounded-md"/>
                          </div>
                          <div>
                              <h1 className="mt-1 w-20"> өдөр *</h1>
                              <Input onChange={(e) => setData({...data, day: e.target.value})} value={data.day} placeholder="10" size="xs" className="mt-2 ring-1 rounded-md"/>
                          </div>
                       </div>
                        <div className="">
                            <h1 className="mt-4">Тэмцээний төрөл *</h1>
                            <select 
                                className="max-w-xs ring-1 rounded-md mt-2 outline-none p-2"
                                onChange={(e) => setData({...data, type:e.target.value})}
                            >
                              <option>Сонгох</option>
                                {WRESTLING_TYPE.map((it, key) => (
                                  <option key={key} value={it.value}>{it.name}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <h1 className="mt-4">Насны ангилал *</h1>
                            <select 
                                className="max-w-xs ring-1 rounded-md mt-2 outline-none p-2"
                                onChange={(e) => setData({...data, category:e.target.value})}
                            >
                              <option>Сонгох</option>
                                {CATEGORY.map((it, key) => (
                                  <option key={key} value={it.value}>{it.value}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <h1 className="mt-4">Тэмцээний ангилал *</h1>
                            <select 
                                className="max-w-xs ring-1 rounded-md mt-2 outline-none p-2"
                                onChange={(e) => setData({...data, event_category:e.target.value})}
                            >
                              <option>Сонгох</option>
                                {WRESTLING_STYLE.map((it, key) => (
                                  <option key={key} value={it.value}>{it.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                {
                  load?
                  <Button color="primary">
                    <Spinner/>
                  </Button>
                  :
                  <Button color="primary" onPress={Submit}>
                    Нэмэх
                  </Button>
                }
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
