import CreateProduct from './Modals/createProduct';
import React, { useEffect, useState } from 'react';
import {  Table,  TableHeader,  TableBody,  TableColumn,  TableRow,  TableCell} from "@nextui-org/table";
import { donation_uri, shop_uri } from '../../../utils/url';
import axios from 'axios';
import { Input, Spinner } from '@nextui-org/react';
import { MdOutlineDelete } from "react-icons/md";
import {toast} from "react-toastify";
import { TYPES } from '../../../utils/types';
import StatusUpdate from './Modals/status-update';
import Paginations from '../../../components/Pagination';


const Donation = () => {
    const [data, setData] = useState([]);
    const [username, setUsername] = useState("");
    const [pagination, setPagination] = useState({limit:20, all:'', totalPage:''});
    const [page, setPage] = useState(1);
    const [load, setLoad] = useState(false);
    const [loaddel, setLoaddel] = useState(false);

    function callback(){
      Get();
    }

    const Get = async () => {
        setLoad(true);
        try{
          const res = await axios.get(donation_uri+`/alladmin?title=${username}&page=${page}&limit=${pagination.limit}`);
          setPagination({...pagination, all:res.data.all, totalPage:res.data.totalPage});
          setData(res.data.data);
          console.log(res.data);
          setLoad(false);
        }catch(err){
          setLoad(false);
          console.log(err);
        }
      }
    
      useEffect(() => {
        Get();
      }, []);
    
      const handleKeySub = (event) => {
        if (event.key === 'Enter') {
          Get();
        }
      };
    
      const changePage = (page) => {
        setPage(page);
      };

      const Delete = async (e) => {
        setLoaddel(true);
        try{
          const res = await axios.delete(shop_uri+`?id=${e}`);
          if(res.status === 200){
            toast.success("Амжилттай устгалаа");
            callback();
          }
          setLoaddel(false);
        }catch(err){
          setLoaddel(false);
          console.log(err);
        }
      }
    

  return (
    <div>
        <div className='bg-white p-4 rounded-lg'>
            <div className='flex justify-between items-center'>
                <h1>Хандивууд</h1>
                <CreateProduct callback={callback}/>
            </div>
        </div>
        <div className='mt-2 bg-white rounded-xl p-4'>
            <Input size='xs' onKeyDown={handleKeySub} value={username} onChange={(e)=> setUsername(e.target.value)} className='mb-4 rounded-full' placeholder='Хайлт хийх' rounded="full"/>
            <Table aria-label="Example static collection table">
                <TableHeader>
                    <TableColumn>Овог нэр</TableColumn>
                    <TableColumn>Имейл</TableColumn>
                    <TableColumn>Утасны дугаар</TableColumn>
                    <TableColumn>Компани</TableColumn>
                    <TableColumn>Илгээсэн мөнгөний хэмжээ</TableColumn>
                    <TableColumn>Өдөр</TableColumn>
                    <TableColumn></TableColumn>
                    <TableColumn></TableColumn>
                </TableHeader>
                <TableBody>
                    {
                        data.map((it, index) => {
                            return(
                                <TableRow key={index}>
                                    <TableCell>
                                     <div>
                                      <h1>{it.lastname}</h1>
                                      <h1 className='font-bold'>{it.firstname}</h1>
                                     </div>
                                    </TableCell>
                                    <TableCell>{it.email}</TableCell>
                                    <TableCell>{it.phone}</TableCell>
                                    <TableCell>{it.company}</TableCell>
                                    <TableCell>{it.money_size}</TableCell>
                                    <TableCell>{it.created_date}</TableCell>
                                    <TableCell>
                                      {
                                        it.status === TYPES.REQUESTED &&
                                        <h1 className='bg-green-600 text-white rounded-md p-1 shadow-md text-xs text-center'>
                                          <span className=''>Шинэ</span>
                                        </h1>
                                      }
                                      {
                                        it.status === TYPES.APPROVED &&
                                        <h1 className='bg-green-600 text-white rounded-md p-1 shadow-md text-xs text-center'>
                                          <span className=''>Баталгаажсан</span>
                                        </h1>
                                      }
                                    </TableCell>
                                    <TableCell>
                                      <StatusUpdate callback={callback} data={it}/>
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
            <Paginations initialPage={page} total={pagination.totalPage} alldata={pagination.all} onChange={changePage}/> 
        </div>
    </div>
  )
}

export default Donation