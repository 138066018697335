import { Button, Input } from '@nextui-org/react'
import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import { AuthContext } from '../../context/auth'

const Login = () => {
    const navigate = useNavigate();
    const {login, currentUser} = useContext(AuthContext);
    const [data, setData] = useState({username:'', password:'', load:false});

    useEffect(() => {
        if(currentUser){
            navigate('/dashboard');
        }
    }, []);

    const sub = async() =>{
        setData({...data, load:true});
       await login(data);
       setData({...data, load:false});
    }

  return (
    <div className='font-Roboto'>
        <div className='grid xs:grid-cols-1 md:grid-cols-3 gap-4'>
            <div className='grid col-span-1 container mt-16'>
                <div>
                    <div className='flex justify-center'>
                        <img className='h-40 rounded-md' src='../../logo.jpg'/>
                    </div>
                    <h1 className='text-xl font-bold text-center mt-10'>Монголын чөлөөт бөхийн холбоо</h1>
                    <p className='text-sm mt-2 text-center'>Админ хуудас</p>
                    <div className='mt-20'>

                        <div className=''>
                            <h1 className='font-bold text-sm'>Нэвтрэх нэр</h1>
                            <Input onChange={(e)=>setData({...data, username:e.target.value})} type="email" label="Нэвтрэх нэр оруулах" size="sm" className="mt-2"/>
                        </div>
                        <div className='mt-2'>
                            <h1 className='font-bold text-sm'>Нууц үг</h1>
                            <Input onChange={(e)=>setData({...data, password:e.target.value})} type="password" label="Нууц үг оруулах" size="sm" className="mt-2"/>
                        </div>

                        {
                            data.load?
                            <Button className='text-white bg-blue-800 w-full mt-10'>Шалгаж байна ... </Button>
                            :
                            <Button onClick={sub} className='text-white bg-blue-800 w-full mt-10'>Нэвтрэх</Button>
                        }

                    </div>
                </div>
            </div>
            <div className='grid col-span-2 relative'>
                <div className='bg-back bg-cover h-screen bg-center'>
                    <div className='rounded-xl absolute bottom-0 left-0 right-0 h-5/6 bg-gradient-to-t from-[#0D0049] to-transparent'></div>
                    <div className='rounded-xl absolute bottom-10 left-6 right-0'>
                       <h1 className='text-gray-400 mt-2'>2024-10-10</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Login