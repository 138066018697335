import React, { useEffect, useState } from "react";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure, Spinner, Input, Textarea} from "@nextui-org/react";
import axios from "axios";
import { toast } from "react-toastify";
import { athletes_uri, other_uri, shop_uri } from "../../../../utils/url";
import { CATEGORY, GENDER } from "../../../../utils/types";

export default function FeaturedAddAthlete({callback, it}) {
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  const [load, setLoad] = useState(false);
  const [athletes, setAthletes] = useState([]); 
  const [data, setData] = useState({
      name:"",kg:"", athlete_id :"", selectedKgs:{}, category:"", gender:"", id: it.id
    });

  const handlecall = () => {
    callback();
  }

  const handleSubmit = async () => {
      setLoad(true);
    try{
        const res = await axios.post(other_uri+"/featured/comp", data);
        if(res.status === 200){
            toast.success("Зураг нэмлээ !");
            handlecall();
            setData(
              {...data, 
                name:"",image:""
              }
            );
            // onOpenChange(false);
        }
        setLoad(false);
    }catch(err){
        console.log(err);
        setLoad(false);
    }
}

useEffect(() => {
  Get()
}, [])



  const Get = async () => {
    setLoad(true);
    try{
      const res = await axios.get(athletes_uri+`/all`);
      setAthletes(res.data)
      setLoad(false);
    }catch(err){
      setLoad(false);
      console.log(err);
    }
  }

  const GetCategory = (e) => {
    const a = CATEGORY.filter((i) => i.value === e);
    setData({...data, category:e, selectedKgs: a[0].data[0]});
    // setForm({...form, category:e});
  }


  return (
    <>
      <Button size="sm" className="bg-gray-200 text-gray-500 ml-2" onPress={onOpen}>Тамирчин нэмэх</Button>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Тамирчин</ModalHeader>
              <ModalBody>
                <div className="w-full mt-2">
                    <h1 className="mb-1 mt-2">Тамирчин</h1>
                    <select className="w-full p-2 bg-gray-100 rounded-md" onChange={(e) => setData({...data, athlete_id: e.target.value})}>
                      <option> Сонгох</option>
                      {
                        athletes.map((it, index) => {
                          return(
                            <option value={it.id} key={index}>{it.lastname} {it.username}</option>
                          )
                        })
                      }
                    </select>

                    <h1 className="mt-2">Жин *</h1>
                    <Input placeholder="Weight" onChange={(e)=> setData({...data, kg: e.target.value})} value={data.kg}/>

                    

                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
               {
                load ?
                <Button color="primary">
                  Loading ... 
                </Button>
                :
                <Button color="primary" onPress={handleSubmit}>
                  Нэмэх
                </Button>
               }
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
