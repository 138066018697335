import React, { useContext, useMemo } from 'react'
import {Accordion, AccordionItem, Button} from '@nextui-org/react'
import {RxDashboard} from 'react-icons/rx'
import {FiArrowLeft, FiLogOut} from 'react-icons/fi'
import {BsPeopleFill} from 'react-icons/bs'
import { Link, useLocation } from 'react-router-dom'
import {HiHome} from 'react-icons/hi'
import { AuthContext } from '../../context/auth'

const Sidebar = () => {
  const {logout} = useContext(AuthContext);
  const location = useLocation();
  const pathname = location.pathname;

  const shop = useMemo(() => [
      {
          icon: HiHome,
          label:'Бараа',
          activate: pathname === '/dashboard/shop',
          href: '/dashboard/shop'
      },
      
  ], [pathname]);

  const delivery = useMemo(() => [
      {
          icon: HiHome,
          label:'Бүртгүүлсэн',
          activate: pathname === '/dashboard/donation',
          href: '/dashboard/donation'
      },
      
  ], [pathname]);

  const news = useMemo(() => [
    {
        icon: HiHome,
        label:'Мэдээ нэмэх',
        activate: pathname === '/dashboard/news-add',
        href: '/dashboard/news-add'
    },
    {
      icon: HiHome,
      label:'Мэдээний жагсаалт',
      activate: pathname === '/dashboard/news-list',
      href: '/dashboard/news-list'
  },
    
  ], [pathname]);

  const orgs = useMemo(() => [
    {
      icon: HiHome,
      label:'Жагсаалт',
      activate: pathname === '/dashboard/orgs',
      href: '/dashboard/orgs'
    },
    
  ], [pathname]);

  const athletes = useMemo(() => [
    {
      icon: HiHome,
      label:'Улсын аваргууд',
      activate: pathname === '/dashboard/national-champs',
      href: '/dashboard/national-champs'
    },
    {
      icon: HiHome,
      label:'Тамирчид',
      activate: pathname === '/dashboard/athletes',
      href: '/dashboard/athletes'
    },
    {
      icon: HiHome,
      label:'Шигшээ баг',
      activate: pathname === '/dashboard/leader-team',
      href: '/dashboard/leader-team'
    },
    {
      icon: HiHome,
      label:'Онцлох тэмцээн',
      activate: pathname === '/dashboard/comp-teams',
      href: '/dashboard/comp-teams'
    },
    
  ], [pathname]);

  const other = useMemo(() => [
    {
      icon: HiHome,
      label:'Подкаст',
      activate: pathname === '/dashboard/podcast',
      href: '/dashboard/podcast'
    },
    {
      icon: HiHome,
      label:'Gallery',
      activate: pathname === '/dashboard/gallery',
      href: '/dashboard/gallery'
    },
    {
      icon: HiHome,
      label:'Цол зэргийн хүсэлт',
      activate: pathname === '/dashboard/odon',
      href: '/dashboard/odon'
    },
    {
      icon: HiHome,
      label:'Санал хүсэлт',
      activate: pathname === '/dashboard/sanal-huselt',
      href: '/dashboard/sanal-huselt'
    },
    
  ], [pathname]);


  return (
    <div className='bg-white rounded-lg m-2 shadow-lg font-Roboto h-full'>
      <div>
        <div className='flex items-center p-2 justify-between hover:bg-gray-200 hover:rounded-t-md cursor-pointer'>
         <Link to="/dashboard" className='flex items-center gap-4'>
            <img className='rounded-lg h-14' src='../../logo.jpg'/>
            <div>
              <h1 className='font-semibold text-sm'>Чөлөөт бөхийн холбоо</h1>
              <p className='text-xs'>Giilen.mn</p>
            </div>
         </Link>
          <FiArrowLeft/>
        </div>
        <div className='mx-4 mt-8'>
          <h1 className='text-gray-600 mb-4'>Menu</h1>
          <Link to="/dashboard" className={pathname === "/dashboard" ? 'flex items-center gap-4 bg-blue-700 text-white rounded-lg p-2 pl-4 cursor-pointer hover:bg-blue-600':
          'flex mt-1 items-center gap-4 pl-4 p-2 rounded-lg hover:bg-gray-200 cursor-pointer'}>
            <RxDashboard size={20}/>
            <h1 className='text-sm'>Нүүр хуудас</h1>
          </Link>

          <Link to="/dashboard/calendar" className={pathname === "/dashboard/calendar" ? 'flex items-center gap-4 bg-blue-700 text-white rounded-lg p-2 pl-4 cursor-pointer hover:bg-blue-600 mt-1':
          'flex mt-1 items-center gap-4 pl-4 p-2 rounded-lg hover:bg-gray-200 cursor-pointer'}>
            <RxDashboard size={20}/>
            <h1 className='text-sm'>Тэмцээний календар</h1>
          </Link>

          <Accordion className=' rounded-lg text-sm mt-1' isCompact>
            <AccordionItem title="Дэлгүүр" className=''>
              {
                shop.map((data, index) => {
                  return(
                    <Link key={index} to={data.href} className={data.activate ? 'flex items-center gap-4 pl-4 bg-blue-700 hover:bg-blue-600 text-white p-2 rounded-lg cursor-pointer mt-1'
                    : 'flex mt-1 items-center gap-4 pl-4 p-2 rounded-lg hover:bg-gray-200 cursor-pointer'}>
                      <BsPeopleFill/>
                      <h1 className=''>{data.label}</h1>
                    </Link>
                  )
                })
              }
            </AccordionItem>
          </Accordion>

          <Accordion className=' rounded-lg text-sm mt-1' isCompact>
            <AccordionItem title="Хандив" className=''>
              {
                delivery.map((data, index) => {
                  return(
                    <Link key={index} to={data.href} className={data.activate ? 'flex items-center gap-4 pl-4 bg-blue-700 hover:bg-blue-600 text-white p-2 rounded-lg cursor-pointer mt-1'
                    : 'flex mt-1 items-center gap-4 pl-4 p-2 rounded-lg hover:bg-gray-200 cursor-pointer'}>
                      <BsPeopleFill/>
                      <h1 className=''>{data.label}</h1>
                    </Link>
                  )
                })
              }
            </AccordionItem>
          </Accordion>

          <Accordion className=' rounded-lg text-sm mt-1' isCompact>
            <AccordionItem title="Мэдээ" className=''>
              {
                news.map((data, index) => {
                  return(
                    <Link key={index} to={data.href} className={data.activate ? 'flex items-center gap-4 pl-4 bg-blue-700 hover:bg-blue-600 text-white p-2 rounded-lg cursor-pointer mt-1'
                    : 'flex mt-1 items-center gap-4 pl-4 p-2 rounded-lg hover:bg-gray-200 cursor-pointer'}>
                      <BsPeopleFill/>
                      <h1 className=''>{data.label}</h1>
                    </Link>
                  )
                })
              }
            </AccordionItem>
          </Accordion>

          <Accordion className=' rounded-lg text-sm mt-1' isCompact>
            <AccordionItem title="Байгууллагууд" className=''>
              {
                orgs.map((data, index) => {
                  return(
                    <Link key={index} to={data.href} className={data.activate ? 'flex items-center gap-4 pl-4 bg-blue-700 hover:bg-blue-600 text-white p-2 rounded-lg cursor-pointer mt-1'
                    : 'flex mt-1 items-center gap-4 pl-4 p-2 rounded-lg hover:bg-gray-200 cursor-pointer'}>
                      <BsPeopleFill/>
                      <h1 className=''>{data.label}</h1>
                    </Link>
                  )
                })
              }
            </AccordionItem>
          </Accordion>

          <Accordion className=' rounded-lg text-sm mt-1' isCompact>
            <AccordionItem title="Тамирчид" className=''>
              {
                athletes.map((data, index) => {
                  return(
                    <Link key={index} to={data.href} className={data.activate ? 'flex items-center gap-4 pl-4 bg-blue-700 hover:bg-blue-600 text-white p-2 rounded-lg cursor-pointer mt-1'
                    : 'flex mt-1 items-center gap-4 pl-4 p-2 rounded-lg hover:bg-gray-200 cursor-pointer'}>
                      <BsPeopleFill/>
                      <h1 className=''>{data.label}</h1>
                    </Link>
                  )
                })
              }
            </AccordionItem>
          </Accordion>

          <Accordion className=' rounded-lg text-sm mt-1' isCompact>
            <AccordionItem title="Бусад" className=''>
              {
                other.map((data, index) => {
                  return(
                    <Link key={index} to={data.href} className={data.activate ? 'flex items-center gap-4 pl-4 bg-blue-700 hover:bg-blue-600 text-white p-2 rounded-lg cursor-pointer mt-1'
                    : 'flex mt-1 items-center gap-4 pl-4 p-2 rounded-lg hover:bg-gray-200 cursor-pointer'}>
                      <BsPeopleFill/>
                      <h1 className=''>{data.label}</h1>
                    </Link>
                  )
                })
              }
            </AccordionItem>
          </Accordion>

        </div>
        <div className='mx-4 mt-40 bottom-10 w-52'>
          <Button onClick={logout} className='flex items-center justify-start gap-4 bg-gray-200 rounded-lg p-2 pl-4 cursor-pointer hover:bg-gray-300 w-full'>
            <FiLogOut size={20}/>
            <h1 className='text-sm'>Гарах</h1>
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Sidebar

