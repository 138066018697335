import React, { useState } from "react";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure, Spinner, Input, Textarea} from "@nextui-org/react";
import axios from "axios";
import { toast } from "react-toastify";
import { other_uri, shop_uri } from "../../../../utils/url";
import { MdOutlineCancel } from "react-icons/md";

export default function CreateGallery({callback}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [load, setLoad] = useState(false);
  const [data, setData] = useState({
      name:""
    });

  const handlecall = () => {
    callback();
  }

  const handleSubmit = async () => {
      setLoad(true);
    try{  
        const res = await axios.post(other_uri+"/gallery", data);
        if(res.status === 200){
            toast.success("Зурагын гарчиг нэмлээ !");
            handlecall();
            setData(
              {...data, 
                name:"",
              }
            );
            setIsModalOpen(false);
        }
        setLoad(false);
    }catch(err){
        console.log(err);
        setLoad(false);
    }
  }


  const handleUpload = (e) => {
    if (e?.size <= 5 * 1024 * 1024) {
        if (e?.type === "image/jpeg" || e?.type === "image/jpg" || e?.type === "image/png") {
            setData({...data, image:e});
        } else {
            toast.error("Зөвхөн jpeg/png өргөтгөлтэй зураг оруулах боломжтой !");
        }
    } else {
        toast.error("Файлын хэмжээ 5MB-с бага байх ёстой!");
    }
  };

  return (
    <>
      <Button className="bg-green-600 text-white ml-2" onPress={()=>setIsModalOpen(true)}>Зураг нэмэх</Button>
      {isModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center z-40">
          <div className="absolute w-full h-full bg-gray-800 opacity-50"></div>
          <div className="bg-white rounded-2xl z-10 xs:w-full xs:h-full md:w-1/2 md:h-1/3 mt-20 p-4">
            <div>
              <div className="flex justify-between items-center mt-4">
                <h1 className="font-bold text-xl">Зурагийн гарчиг нэмэх</h1>
                <MdOutlineCancel className="cursor-pointer " onClick={()=> setIsModalOpen(false)}/>
              </div>
              <div className="w-full mt-4">
                  <h1>Зурагийн гарчиг</h1>
                  <Input size="xs" className="mt-2 ring-1 rounded-xl" placeholder="Гарчиг оруулах" onChange={(e)=> setData({...data, name:e.target.value})}/>
              </div>
              <div className="flex justify-end mt-20">
                {
                  load ?
                  <Button className="w-full"><Spinner/></Button>
                  :
                  <Button onPress={handleSubmit} className="w-full">Нэмэх</Button>
                }
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
