import React, { useState } from "react";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter,
     Button, useDisclosure,
     Spinner
    } from "@nextui-org/react";
import axios from "axios";
import { events_uri } from "../../../../utils/url";
import {toast} from "react-toastify";
import { CiEdit } from "react-icons/ci";


export default function EditModal({callback, id}) {
  const [load, setLoad] = useState(false);
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  const [data, setData] = useState();

  const call = () => {
    callback();
  }

  const Submit = async()=>{
    setLoad(true);
    const form = new FormData();
    form.append('data', data);

    try{  
      const res = await axios.patch(events_uri+`/pdf/${id}`, form);
      if(res.status === 200){
        toast.success("Амжилттай !");
        call();
        onOpenChange(false);
      }
      setLoad(false);
    }catch(err){
      console.log(err);
      setLoad(false);
    }
  }

  const handleUpload = (e) => {
    if (e?.size <= 10 * 1024 * 1024) {
      console.log(e.type)
        if (e.type === "application/pdf") {
            setData(e);
        } else {
            toast.error("Зөвхөн pdf өргөтгөлтэй зураг оруулах боломжтой !");
        }
    } else {
        toast.error("Файлын хэмжээ 10MB-с бага байх ёстой!");
    }
  };


  return (
    <>
      <CiEdit className="cursor-pointer" onClick={onOpen}/>
      <Modal size="3xl" isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Тэмцээний үр дүн оруулах</ModalHeader>
              <ModalBody>
                <div>
                    <h1>Pdf file оруулах *</h1>
                    <input onChange={(e) => handleUpload(e.target.files[0])} className="mt-2" type="file"/>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                {
                  load?
                  <Button color="primary">
                    <Spinner color="white"/>
                  </Button>
                  :
                  <Button color="primary" onPress={Submit}>
                    Нэмэх
                  </Button>
                }
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
