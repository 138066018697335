import React, { useState } from "react";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure, Spinner, Input, Textarea} from "@nextui-org/react";
import axios from "axios";
import { athletes_uri, news_uri } from "../../../../utils/url";
import { toast } from "react-toastify";

export default function AthleteAdd({callback}) {
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  const [load, setLoad] = useState(false);
  const [data, setData] = useState({lastname:"", username:"", club:"", birth_date:"", weight:"", biography:"", profile_img:""});

  const handlecall = () => {
    callback();
  }

  const handleUpload = (e) => {
    if (e?.size <= 5 * 1024 * 1024) {
        if (e?.type === "image/jpeg" || e?.type === "image/jpg" || e?.type === "image/png") {
            setData({...data, profile_img:e});
        } else {
            toast.error("Зөвхөн jpeg/png өргөтгөлтэй зураг оруулах боломжтой !");
        }
    } else {
        toast.error("Файлын хэмжээ 5MB-с бага байх ёстой!");
    }
  };

  const handleSubmit = async () => {
    setLoad(true);
    const form = new FormData();
    form.append('profile_img', data.profile_img);
    form.append('lastname', data.lastname);
    form.append('username', data.username);
    form.append('club', data.club);
    form.append('birth_date', data.birth_date);
    form.append('weight', data.weight);
    form.append('biography', data.biography);

    try{
        const res = await axios.post(athletes_uri, form);
        if(res.status === 200){
            toast.success("Тамирчныг нэмлээ !");
            onOpenChange(false);
            handlecall();
        }
        setLoad(false);
    }catch(err){
        console.log(err);
        setLoad(false);
    }
  }

  return (
    <>
      <Button className="bg-blue-600 text-white ml-2" onPress={onOpen}>Тамирчин нэмэх</Button>
      <Modal size="4xl" isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Тамирчины мэдээлэл</ModalHeader>
              <ModalBody>
                <div className="text-sm">
                    <h1>Тамирчины овог *</h1>
                    <Input value={data.lastname} onChange={(e) => setData({...data, lastname:e.target.value})} size="xs" className="ring-1 rounded-xl mt-1" placeholder="Овог"/>

                    <h1 className="mt-2">Тамирчины нэр *</h1>
                    <Input value={data.username} onChange={(e) => setData({...data, username:e.target.value})} size="xs" className="ring-1 rounded-xl mt-1" placeholder="Нэр"/>

                    <h1 className='mt-2'>Зураг оруулах *</h1>
                    <Input onChange={(e) => handleUpload(e.target.files[0])} type="file" size='xs' className='ring-1 rounded-xl mt-1 w-80'/>

                    <h1 className="mt-2">Спорт клуб эсвэл байгууллага *</h1>
                    <Input value={data.club} onChange={(e) => setData({...data, club:e.target.value})} size="xs" className="ring-1 rounded-xl mt-1" placeholder="Спорт клуб эсвэл байгууллага"/>

                    <h1 className="mt-2">Төрсөн он *</h1>
                    <Input value={data.birth_date} onChange={(e) => setData({...data, birth_date:e.target.value})} size="xs" className="ring-1 rounded-xl mt-1" placeholder="Төрсөн он"/>

                    <h1 className="mt-2">Жин *</h1>
                    <Input value={data.weight} onChange={(e) => setData({...data, weight:e.target.value})} size="xs" className="ring-1 rounded-xl mt-1" placeholder="Жин"/>

                    <h1 className="mt-2">Танилцуулга *</h1>
                    <Textarea value={data.biography} onChange={(e) => setData({...data, biography:e.target.value})} size="xs" className="ring-1 rounded-xl mt-1" placeholder="Танилцуулга"/>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Болих
                </Button>
                {
                  load ?
                  <Button className='bg-green-600 text-white ml-2'><Spinner/></Button>
                  :
                  <Button className='bg-green-600 text-white ml-2' onPress={handleSubmit}>Нэмэх</Button>
                }
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
