import {Route, Routes} from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Home from './pages/Dashboard/Home';
import Login from './pages/Login';
import Calendar from './pages/Dashboard/Calendar';
import Donation from './pages/Dashboard/Donation';
import Shop from './pages/Dashboard/Shop';
import NewsAdd from './pages/Dashboard/News/news-add';
import NationalChamps from './pages/Dashboard/Athletes/national-champs';
import Athletes from './pages/Dashboard/Athletes/athletes';
import NewsList from './pages/Dashboard/News/news-list';
import LeaderTeam from './pages/Dashboard/Athletes/leader-team';
import Podcast from './pages/Dashboard/Other';
import Gallery from './pages/Dashboard/Other/gallery';
import Odon from './pages/Dashboard/Other/odon';
import SanalHuselt from './pages/Dashboard/Other/sanal-huselt';
import Orgs from './pages/Dashboard/Orgs/orgs';
import CompTeams from './pages/Dashboard/Athletes/comp-teams';

function App() {
  return (
    <>
      <Routes>
          <Route path='/' element={<Login/>}/>
          <Route path="/dashboard" element={<Dashboard />}>

          <Route index element={<Home/>}/>
            <Route path="calendar" element={<Calendar/>}/>
            <Route path="shop" element={<Shop/>}/>
            <Route path="donation" element={<Donation/>}/>
            <Route path="news-add" element={<NewsAdd/>}/>
            <Route path="news-list" element={<NewsList/>}/>
            <Route path="national-champs" element={<NationalChamps/>}/>
            <Route path="comp-teams" element={<CompTeams/>}/>
            <Route path="athletes" element={<Athletes/>}/>
            <Route path="leader-team" element={<LeaderTeam/>}/>
            <Route path="podcast" element={<Podcast/>}/>
            <Route path="gallery" element={<Gallery/>}/>
            <Route path="odon" element={<Odon/>}/>
            <Route path="sanal-huselt" element={<SanalHuselt/>}/>
            <Route path="orgs" element={<Orgs/>}/>
          </Route>
      </Routes>
    </>
  );
}

export default App;
